import React, {useEffect, useMemo, useRef, useState} from "react";
import styles from "./inventario.module.css";
import Searchbar from "../searchbar/searchbar";
import Swmtable from "../table/swmtable";
import Chart from "chart.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload, faTrashRestore} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";
import ExcelJS from "exceljs";
import {saveAs} from "file-saver";

const Inventario = (props) => {
    let history = useHistory();
    const clientesInicial = [...props.response["clientes"]];
    const inventarioInicial = [...props.response["inventario"]];
    const inventarioDepurado = inventarioInicial.filter(equipo => equipo["descartado"] === 0);
    const ViewTableHeaders = ["Equipo", "Marca", "Modelo", "Serie", "Activo", "Ubicación", "Fecha de Servicio", "Ultimo Servicio"];

    const [tableRows, setTableRows] = useState(inventarioDepurado);
    const [searchBarTables, setSearchBarTables] = useState([{
        "initialValues": inventarioDepurado,
        "searchParameters": [
            "serie", "activo", "modelo", "marca", "ubicacion", "equipo", "certificado1", "certificado2",
            "certificado3", "fecha1", "fecha2", "fecha3"
        ],
        "setValues": setTableRows
    }]);

    const makeSummaryState = () => {
        let rows = [];

        clientesInicial.forEach((cliente) => {
            const departamentos = cliente["departamentos"];
            rows.push({
                "text": cliente["cliente"],
                "value": 0,
                "rowtype": "cliente",
                "visibility": true,
                "classname": styles.viewSummaryCliente,
                "clienteid": cliente["id"]
            });
            let current_cliente_index = rows.length - 1;

            departamentos.forEach((departamento) => {
                rows[current_cliente_index]["value"] = rows[current_cliente_index]["value"] + departamento["total"];
                const equipos = departamento["equipos"];
                rows.push({
                    "text": departamento["departamento"],
                    "value": departamento["total"],
                    "rowtype": "departamento",
                    "visibility": true,
                    "classname": styles.viewSummaryDepartamento,
                    "clienteid": cliente["id"],
                    "departamentoid": departamento["id"]
                });

                equipos.forEach((equipo) => {
                    rows.push({
                        "text": equipo["equipo"],
                        "value": equipo["total"],
                        "classname": styles.viewSummaryEquipo,
                        "rowtype": "equipo",
                        "visibility": true,
                        "clienteid": cliente["id"],
                        "departamentoid": departamento["id"],
                        "equipoid": equipo["id"]
                    });

                });

            });

        });

        return rows

    };

    const makeEquipoDataset = () => {
        let dataset = {};
        clientesInicial.forEach((cliente) => {
            cliente["departamentos"].forEach((departamento) => {
                departamento["equipos"].forEach((equipo) => {
                    equipo["equipo"] in dataset ? dataset[equipo["equipo"]] = dataset[equipo["equipo"]] + equipo["total"] : dataset[equipo["equipo"]] = equipo["total"];

                });
            });
        });

        const equipos = Object.keys(dataset);
        let presorted = equipos.map((equipo) => {
            return [equipo, dataset[equipo]];
        });

        return presorted.sort((a, b) => {
            return a[1] - b[1]
        });

    };

    return (
        <section className={styles.viewSection}>
            <div>
                <div className={styles.viewModuleRow}>
                    <ViewSummary data={makeSummaryState()}/>
                    <ViewGraph
                        data1={makeEquipoDataset()}/>
                </div>
                <div className={styles.viewToolbar}>
                    <Searchbar data={searchBarTables}/>
                    <div className={styles.toolbarButtonsContainer}>
                        <ExportXLSX dataset={searchBarTables[0]["initialValues"]} fileName={"inventario-equipos"}/>
                        <ButtonDescarte invInicial={inventarioInicial}
                                        invDepurado={inventarioDepurado}
                                        setTableRows={setTableRows}
                                        setSearchBarTables={setSearchBarTables}/>
                    </div>
                </div>
                <Swmtable
                    history={history}
                    headers={ViewTableHeaders}
                    rows={tableRows}
                    type={"inventario"}
                />
            </div>
        </section>
    );
};

const ViewSummary = (props) => {
    const [rows, setRows] = useState([...props.data]);

    const changeRowVisibility = (r) => {
        let temporalRow = r;

        if (r["visibility"]) {
            temporalRow["visibility"] = false;
            temporalRow["classname"] = styles.viewSummarHidden;
        } else {
            temporalRow["visibility"] = true;
            temporalRow["classname"] = styles.viewSummaryEquipo;
        }

        return temporalRow;

    };

    const toggleRows = (i) => {
        let newRows = [...rows];
        const rowtype = rows[i]["rowtype"];
        const cliente_id = rows[i]["clienteid"];
        const departamento_id = rows[i]["departamentoid"];

        if (rowtype === "departamento") {
            rows.forEach((row, index) => {
                if (row["rowtype"] === "equipo" && row["clienteid"] === cliente_id && row["departamentoid"] === departamento_id) {
                    newRows[index] = changeRowVisibility(row);
                }

            });

            setRows(newRows);

        }

    };

    return (
        <div className={styles.viewModule}>
            <header>
                <span>Resumen</span>
            </header>
            <div className={styles.viewSummary}>
                <table>
                    <tbody>
                    {rows.map((row, index) => {
                        return (
                            <tr key={index} className={row["classname"]} onClick={() => toggleRows(index)}>
                                <td>{row["text"]}</td>
                                <td>{row["value"]}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const ViewGraph = (props) => {
    const labels = [];
    const values = [];
    let cfg = useMemo(() => {
        return {};
    }, []);
    let myChart = useRef();

    useEffect(() => {
        myChart.current = new Chart("myChart", cfg);
    }, [myChart, cfg]);

    const getColors = (labelsLength, alpha) => {
        // alpha === 1: border
        // alpha === 0.2: background
        let colors = [
            `rgba(255, 99, 132, ${alpha})`, // Red
            `rgba(54, 162, 235, ${alpha})`, // Blue
            `rgba(255, 206, 86, ${alpha})`, // Yellow
            `rgba(75, 192, 192, ${alpha})`, // Green
            `rgba(153, 102, 255, ${alpha})`, // Purple
            `rgba(255, 159, 64, ${alpha})` // Green
        ];

        if (labelsLength > colors.length) {
            for (let i = 7; i <= labelsLength; i++) {
                colors.push(colors[i % 6]);
            }
        }

        return colors;

    };

    props.data1.forEach((arr) => {
        labels.push(arr[0]);
        values.push(arr[1]);
    });
    const total_values = values.reduce((a, b) => a + b);
    const percentage = values.map((value) => {
        return ((Math.round((value / total_values) * 1000) / 1000) * 100).toFixed(1);
    });
    const legendDisplay = window.innerWidth >= 1024;

    cfg = {
        type: "doughnut",
        data: {
            labels: labels,
            datasets: [{
                borderAlign: "inner",
                backgroundColor: getColors(labels.length, 0.5),
                data: percentage,
            }]
        },
        options: {
            legend: {
                display: legendDisplay,
                position: "right",
            },
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    label: function (tooltipItems, data) {
                        const index = tooltipItems["index"];
                        const label = data["labels"][index];
                        const value = data.datasets[0].data[index];
                        return label + ": " + value + "%";
                    }
                }
            }
        },
    };

    return (
        <div className={styles.viewModule}>
            <header>
                <span>Total por Equipos</span>
            </header>
            <div>
                <canvas id={"myChart"}/>
            </div>
        </div>
    );
};

const ExportXLSX = (props) => {
    const emptyPlaceholder = "N/A";
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const spanishMonth = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
    "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    const spanishTimeLapse = {
      1: "Mensual",
      2: "Bimestral",
      3: "Trimestral",
      4: "Cuatrimestral",
      6: "Semestral",
      12: "Anual"
    }

    const cronogramaFechas = (dateString, addMonths = 0) => {
      const date = new Date(dateString);

      if (addMonths > 0) {
        const forwardDate = new Date(date.setMonth(date.getMonth() + addMonths));
        return `${spanishMonth[forwardDate.getMonth()]} ${forwardDate.getFullYear()}`
      }

      return `${spanishMonth[date.getMonth()]} ${date.getFullYear()}`

    }

    const certificadosColumn = (row) => {
      const certificado1 = row.certificado1 ? `${row.certificado1}, ` : "";
      const certificado2 = row.certificado2 ? `${row.certificado2}, ` : "";
      const certificado3 = row.certificado3 ? `${row.certificado3}` : "";

      const cellValue = certificado1 + certificado2 + certificado3

      if (cellValue.length === 0) return emptyPlaceholder;

      return cellValue;

    }

    const getFechaServicioXLSX = (row) => {
        if (row["fecha1"]) return row["fecha1"];
        if (row["fecha2"]) return row["fecha2"];
        if (row["fecha3"]) return row["fecha3"];

        return emptyPlaceholder;
    };

    const makeXLSXDataset = (values) => {
        return values.map((row, index) => {
            const fechaServicio = getFechaServicioXLSX(row);
            return ({
                "Número": index + 1,
                "Equipo": row["equipo"],
                "Marca": row["marca"],
                "Modelo": row["modelo"],
                "Serie": row["serie"],
                "Activo": row["activo"],
                "Ubicacion": row["ubicacion"],
                "Certificados": certificadosColumn(row),
                "Frecuencia de Servicio": spanishTimeLapse[row["periodo"]] ? spanishTimeLapse[row["periodo"]] : emptyPlaceholder,
                "Fecha de Servicio": fechaServicio === emptyPlaceholder? fechaServicio : cronogramaFechas(fechaServicio),
                "Fecha de Proximo Servicio": fechaServicio === emptyPlaceholder ? fechaServicio : cronogramaFechas(fechaServicio, row["periodo"])
            });
        });
    };

    const generateFile = async (dataset, fileName) => {

        try {
            const response = await fetch("https://s3.us-east-2.amazonaws.com/www.sigcsapanama.com/assets/plantilla-cronograma.xlsx");
            const blob = await response.blob();
            const arrayBuffer = await blob.arrayBuffer();

            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(arrayBuffer);

            const worksheet = workbook.getWorksheet(1);

            dataset.forEach((item, index) => {
                worksheet.getCell(`B${index + 6}`).value = `${index + 1}`;
                worksheet.getCell(`C${index + 6}`).value = item['Equipo'];
                worksheet.getCell(`D${index + 6}`).value = item['Marca'];
                worksheet.getCell(`E${index + 6}`).value = item['Modelo'];
                worksheet.getCell(`F${index + 6}`).value = item['Serie'];
                worksheet.getCell(`G${index + 6}`).value = item['Activo'];
                worksheet.getCell(`H${index + 6}`).value = item['Ubicacion'];
                worksheet.getCell(`I${index + 6}`).value = item['Frecuencia de Servicio'];
                worksheet.getCell(`J${index + 6}`).value =  item['Fecha de Servicio'];
                worksheet.getCell(`K${index + 6}`).value = item['Fecha de Proximo Servicio'];
            });

            workbook.xlsx.writeBuffer().then((buffer) => {
                const data = new Blob([buffer], { type: fileType });
                saveAs(data, fileName);
            });
        }
        catch (error) {
          alert('Error descargando el cronograma. Si el problema persiste notificar error a tecnologia@sigcsapanama.com');
          console.log(error);
        }

    };

    return (
        <div>
            <button className={`${styles.toolbarButton} ${styles.DownloadButton}`}
                    onClick={() => generateFile(makeXLSXDataset(props.dataset), props.fileName)}>
                <FontAwesomeIcon icon={faFileDownload} size={"lg"}/>
                <span>Descargar Cronograma</span>
            </button>
        </div>
    );
};

const ButtonDescarte = (props) => {
    const [descartados, setDescartados] = useState(0);
    const [CSS, setCSS] = useState(`${styles.toolbarButton} ${styles.DescarteButton}`);
    const inicial = props.invInicial;
    const depurado = props.invDepurado;

    const buttonHandler = () => {
        document.getElementById("sbar").value = "";

        if (descartados === 0) {
            setCSS(`${styles.toolbarButton} ${styles.DescarteButtonPressed}`);
            setDescartados(1);
            props.setSearchBarTables([{
                "initialValues": inicial,
                "searchParameters": [
                    "serie", "activo", "modelo", "marca", "ubicacion", "equipo", "certificado1", "certificado2",
                    "certificado3", "fecha1", "fecha2", "fecha3"
                ],
                "setValues": props.setTableRows
            }]);
            props.setTableRows(inicial);

        } else if (descartados === 1) {
            setCSS(`${styles.toolbarButton} ${styles.DescarteButton}`);
            setDescartados(0);
            props.setSearchBarTables([{
                "initialValues": depurado,
                "searchParameters": [
                    "serie", "activo", "modelo", "marca", "ubicacion", "equipo", "certificado1", "certificado2",
                    "certificado3", "fecha1", "fecha2", "fecha3"
                ],
                "setValues": props.setTableRows
            }]);
            props.setTableRows(depurado);

        }
    };

    return (
        <div>
            <button className={CSS} onClick={buttonHandler}>
                <FontAwesomeIcon icon={faTrashRestore} size={"lg"}/>
                <span>Descartados</span>
            </button>
        </div>
    );
};

export default Inventario;

// Referencias
//
// 1. https://stackoverflow.com/questions/5092808/how-do-i-randomly-generate-html-hex-color-codes-using-javascript
// 2. https://stackoverflow.com/questions/45835151/react-table-row-show-column-on-button-click
// 3. https://www.chartjs.org/docs/latest/configuration/tooltip.html#label-callback
// 4. https://blog.bitsrc.io/exporting-data-to-excel-with-react-6943d7775a92
// 5. https://reactjs.org/docs/fragments.html
// 6. https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
// 7. https://stackoverflow.com/questions/46138145/functions-in-stateless-components
