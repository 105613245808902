import React, {useEffect, useState} from "react";
import styles from "./navbar.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWarehouse, faSignOutAlt, faBars, faFileAlt} from "@fortawesome/free-solid-svg-icons";
import {useHistory, useLocation} from "react-router-dom";

const Navbar = () => {

    let history = useHistory();
    let location = useLocation();

    const [state, setState] = useState({
        "inventario": styles.navbarItemSelected,
        "certificados": styles.navbarItem
    });

    useEffect(() => {
        switch (location.pathname) {
            case "/inventario":
                setState({
                    "inventario": styles.navbarItemSelected,
                    "certificados": styles.navbarItem
                });
                break;
            case "/certificados":
                setState({
                    "inventario": styles.navbarItem,
                    "certificados": styles.navbarItemSelected
                });
                break;
            default:
                break;
        }
    }, [location.pathname]);

    const togglMobileSection = () => {
        let section = document.getElementById("navbarSection").style;
        return section.display === "block" ? section.display = "none" : section.display = "block";
    };

    const sessionLogout = () => {
        sessionStorage.clear();
        history.push("/");
    };

    return (
        <nav className={styles.navbarContainer}>
            <div>
                <header className={styles.navbarHeader}>
                    <div className={styles.navbarHeaderLogo}>
                        <img src={"https://s3.us-east-2.amazonaws.com/www.sigcsapanama.com/assets/logo.png"}
                             alt="SIGCSA"/>
                    </div>
                    <div className={styles.navbarHeaderText}>
                        Web Management
                    </div>
                    <div className={styles.navbarHeaderUsername}>
                        {sessionStorage.getItem("username")}
                    </div>
                    <div className={styles.navbarHeaderMobile} onClick={togglMobileSection}>
                        <FontAwesomeIcon icon={faBars} size={"2x"}/>
                    </div>
                </header>
                <section className={styles.navbarSection} id={"navbarSection"}>
                    <ul>
                        <li className={state["inventario"]} onClick={() => history.push("/inventario")}>
                            <FontAwesomeIcon icon={faWarehouse}/>
                            <span>Inventario</span>
                        </li>
                        <li className={state["certificados"]} onClick={() => history.push("/certificados")}>
                            <FontAwesomeIcon icon={faFileAlt}/>
                            <span>Certificados</span>
                        </li>
                        <li className={styles.navbarItem} onClick={sessionLogout}>
                            <FontAwesomeIcon icon={faSignOutAlt}/>
                            <span>Cerrar Sesión</span>
                        </li>
                    </ul>
                </section>
            </div>
        </nav>
    );
};

export default Navbar;