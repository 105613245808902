import React from "react";
import styles from "./loading.module.css";
import { PulseLoader } from "react-spinners";

const Loading = () => {
    // Can be a string as well. Need to ensure each key-value pair ends with ;
    const override = "margin: 0";

    return (
        <div className={styles.loadingContainer}>
            <PulseLoader color={"#EBCEA6"} loading={true} css={override} margin={10} speedMultiplier={0.5}/>
        </div>
    )
};

export default Loading