import React from "react";
import styles from "./swmbutton.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook} from "@fortawesome/free-solid-svg-icons";

const SwmButton = ({ data }) => {

    if (data["manual"] !== "") {
        return (
            <div>
                <a className={styles.noAnchorStyle} href={data["manual"]} target={"_blank"} rel={"noopener noreferrer"}>
                    <button className={`${styles.swmButton} ${styles.buttonManual}`}>
                        <FontAwesomeIcon icon={faBook} size={"lg"}/>
                        <span>Manual de Operación</span>
                    </button>
                </a>
            </div>
        );
    }

    return (
        <div>
            <abbr title={"Manual no disponible"}>
                <button className={`${styles.swmButton} ${styles.buttonDisabled}`} disabled={true}>
                    <FontAwesomeIcon icon={faBook} size={"lg"}/>
                    <span>Manual de Operación</span>
                </button>
            </abbr>
        </div>
    );
};

export default SwmButton;
