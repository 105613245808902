import React from "react";
import styles from "./searchbar.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

const Searchbar = (props) => {
    const data = [...props.data];

    const normalizeText = (text) => {
      const loweredCase = `${text}`.toLowerCase();
      return loweredCase.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const getRowValues = (obj, keys) => {
        const values = keys.map((key) => {
            const value = obj[key];
            return value === undefined ? "" :  value;
        });

        return values.join("|").slice(0, -1);
    };

    const inputHandler = (e) => {
        const searchValue = normalizeText(e.target.value);
        data.forEach((table) => {
            const baseValues = [...table.initialValues];
            const searchKeys = [...table.searchParameters];
            const filteredValues = baseValues.filter((row) => {
                const validatedValues = getRowValues(row, searchKeys);
                const searchValidation = normalizeText(validatedValues);
                return searchValidation.includes(searchValue);
            });
            table.setValues(filteredValues);
        });
    };

    return (
        <div className={styles.searchbarContainer}>
            <FontAwesomeIcon icon={faSearch}/>
            <input id={"sbar"} className={props.theme} type={"text"} placeholder={"Buscar"} onChange={(event) => inputHandler(event)}/>
        </div>
    );
};

export default Searchbar;

// Referencias
// 1. https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript