import axios from "axios";

export const instance = axios.create({
    baseURL: process.env.REACT_APP_WEBAPP, // reference: 3
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 15000
});

export const getToken = (user, pass) => {
    const data = {
        'username': user,
        'password': pass
    };

    return instance.post('api-token-auth', data).then(response => {
        if (response.data.token) {
            sessionStorage.setItem('token', response.data.token); // Guardamos el token el sessionStorage para que pueda ser accedido por <App/>
            return response.data.token;
        }
    }).catch(error => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx, error de credenciales
            throw new Error('Login');
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            throw new Error('Request');
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error('Unknown');
        }
    });
};

export const getUser = (tk) => {
    instance.defaults.headers.common['Authorization'] = `Token ${tk}`; // Definimos el token para los próximos request de instance, Borrar cuando el api este listo.
    return instance.get('auth').then(response => {
        return response.data[0] ? ` ${response.data[0]["first_name"]} ${response.data[0]["last_name"]}` : undefined;
    });
};

export const getInventario = (tk) => {
    instance.defaults.headers.common['Authorization'] = `Token ${tk}`; // Definimos el token para los próximos request de instance, Borrar cuando el api este listo.
    return instance.get('inventario').then(response => {
        return response["data"];
    }).catch(error => {
        console.error("Request Failed");
        console.error(error.response);
    });
};

export const getInventarioItem = (path) => {
    return instance.get('inventario' + path).then(response => {
        return response;
    }).catch(error => {
        console.error("Request Failed");
        console.error(error.response);
    });
};

export const getReportes = () => {
    return instance.get('reportes').then(response => {
        return response["data"];
    }).catch(error => {
        console.error("Request Failed");
        console.error(error.response);
    });
};

export const getCertificadosArchived = (requestBody, queryStrings) => {
    return axios({
        method: 'post',
        url: process.env.REACT_APP_WEBAPP + "certificados",
        responseType: 'blob',
        headers: {'Authorization':`Token ${sessionStorage.getItem("token")}`},
        data: requestBody,
        params: queryStrings
    }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'archive.zip'); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
};

export const getCertificadosFromEtiqueta = (etiquetaId) => {
    return instance.get(`qr/${etiquetaId}`).then(response => {
        return response.data
    });
};

// Referencias
// 1. https://github.com/axios/axios#creating-an-instance
// 2. https://github.com/axios/axios#config-defaults
// 3. https://stackoverflow.com/questions/49579028/adding-an-env-file-to-react-project
