import React, {useEffect, useState} from "react";
import styles from "./auth.module.css";
import {getToken, getUser} from "../../helpers";
import {useHistory} from "react-router-dom";
import {useAlert} from "react-alert";

const Auth = () => {
    const alert = useAlert();
    const [buttonstate, setButtonState] = useState(false);
    const [inputsValues, setInputsValues] = useState({
        'username': 0,
        'password': 0
    });

    useEffect(() => {
        inputsValues.username >= 5 && inputsValues.password >= 5 ? setButtonState(true) : setButtonState(false);
    }, [inputsValues.username, inputsValues.password]);

    let history = useHistory();

    const buttonHandler = () => {
        const username = document.getElementById("username").value;
        const password = document.getElementById("password").value;

        getToken(username, password).then(isToken => {
            if (isToken) {
                getUser(isToken).then(name => {
                    sessionStorage.setItem("username", name.toString());
                    history.push("/inventario");
                });
            }
        }).catch(error => {
            if (error.message === 'Login') {
                document.getElementById("errorText").textContent = "Verifique el usuario y la contraseña";
                alert.error("Error: Credenciales Incorrectas");
            } else if (error.message === 'Request') {
                document.getElementById("errorText").textContent = "Si el mensaje insiste llamar al 390-9275, o escribir a tecnologia@sigcsapanama.com";
                alert.error("Error 500: Intente nuevamente en 1 minuto");
            }
        });

    };

    const validateInput = (event) => {
        setInputsValues({
            ...inputsValues,
            [event.target.id]: event.target.value.length
        });
    };

    const keyDownHandler = (event) => {
        if (event.key === "Enter") {
            buttonHandler();
        }
    };

    const buttonOn = <input className={styles.loginBoxButton} type="button" value="Iniciar Sesión" onClick={buttonHandler}/>;
    const buttonOff = <input className={styles.loginBoxButtonDisabled} type="button" value="Iniciar Sesión" disabled={true}/>;

    return (
        <div className={styles.authBackground}>
            <div className={styles.authContainer}>
                <img className={styles.authlogo} src={"https://s3.us-east-2.amazonaws.com/www.sigcsapanama.com/assets/logo.png"} alt="SIGCSA"/>
                <div className={styles.authLoginBox}>
                    <div className={styles.loginBoxHeader}>
                        <span className={styles.loginBoxText}>S.W.M</span>
                        <span className={styles.loginBoxText}>Sigcsa Web Management</span>
                    </div>
                    <form className={styles.formContainer}>
                        <input className={styles.loginBoxInput} onChange={validateInput} onKeyDown={keyDownHandler} type="text"
                               placeholder="Usuario"
                               id="username" required/>
                        <input className={styles.loginBoxInput} onChange={validateInput} onKeyDown={keyDownHandler} type="password"
                               placeholder="Contraseña" id="password" required/>
                        {buttonstate ? buttonOn : buttonOff}
                    </form>
                    <div className={styles.errorMsg}>
                        <span id={"errorText"}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Auth;

// Referencias
// 1. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax