import React from 'react';
import Auth from './components/auth/auth';
import IsAuth from "./components/isAuth/isAuth";
import {Switch, Route, Redirect} from "react-router-dom";
import {BrowserRouter} from "react-router-dom";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-mui";
import Error404 from "./components/error404/error404";
import Qrcode from "./views/qrcode";

const App = () => {
    const alertOptions = {
        timeout: 5000,
        position: positions.BOTTOM_CENTER
    };

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/auth">
                    <Provider template={AlertTemplate} {...alertOptions}>
                        <Auth/>
                    </Provider>
                </Route>
                <Route path="/qr/:etiquetaId" component={Qrcode} />
                <Route path="/404">
                    <Error404/>
                </Route>
                <PrivateRoute path="/certificados">
                    <IsAuth/>
                </PrivateRoute>
                <PrivateRoute path="/inventario">
                    <IsAuth/>
                </PrivateRoute>
                <PrivateRoute path="/">
                    <Redirect to="/inventario"/>
                </PrivateRoute>
            </Switch>
        </BrowserRouter>
    );
};

const PrivateRoute = ({ children }) => {
    const token = sessionStorage.getItem("token");
    return (
        token ? children : <Redirect to="/auth"/>
    );
};

export default App;

// Referencias
// 1. https://reactrouter.com/web/guides/primary-components
// 2. https://reactrouter.com/web/api/Switch
// 3. https://reactrouter.com/web/api/Route
// 4. https://stackoverflow.com/questions/54927622/usestate-do-double-render