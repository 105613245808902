import React, {useEffect, useState} from "react";
import styles from "./header.module.css";
import {useLocation} from "react-router-dom";

const Header = () => {
    let location = useLocation();
    const [state, setState] = useState();

    useEffect(() => {
        const path = location.pathname;
        if (path.includes("inventario")) {
            setState("Inventario");
        }
        else if (path.includes("certificados")) {
            setState("Certificados");
        }
    }, [location.pathname]);

    return (
        <header className={styles.headerContainer}>
            <h2>{state}</h2>
        </header>
    );
};

export default Header;