import React from "react";
import styles from "./swmtable.module.css";

const SwmTable = (props) => {
    const header = [...props.headers];
    const rows = [...props.rows];
    const type = props.type;
    let tableRows;

    const getTitle = (title) => {
        if (title) {
            return <h3 className={styles.viewTableTitle}>{title}</h3>
        }
    };

    const getRowsInventario = (values) => {
        const handleRowClick = (values) => {
            const path = `/${values["equipoid"]}/${values["mainid"]}`;
            props.history.push(`/inventario${path}`);
        };

        const getInventarioColumns = (row) => {
            let values = [];
            let dates = [];

            if (row["certificado1"]) {
                values.push({"certificado": row["certificado1"], "url": row["url1"]});
                dates.push(row["fecha1"]);
            }

            if (row["certificado2"]) {
                values.push({"certificado": row["certificado2"], "url": row["url2"]});
                dates.push(row["fecha2"]);
            }

            if (row["certificado3"]) {
                values.push({"certificado": row["certificado3"], "url": row["url3"]});
                dates.push(row["fecha3"]);
            }

            return (
                <React.Fragment>
                    <td onClick={() => handleRowClick(row)}>
                        <ul className={styles.viewTableList}>
                            {dates.map((date, index) => {
                                return <li key={index} className={styles.viewTableListDate}>{date}</li>
                            })}
                        </ul>
                    </td>
                    <td>
                        <ul className={styles.viewTableList}>
                            {values.map((value, index) => {
                                if (value["url"]) {
                                    return (
                                        <a key={index} href={value["url"]} target={"_blank"}
                                           rel={"noopener noreferrer"}>
                                            <li className={styles.viewTableListItem}>{value["certificado"]}</li>
                                        </a>

                                    );
                                }
                                return (
                                    <abbr title={"Pendiente por Aprobación"}>
                                        <li className={styles.viewTableListItemDisabled}>{value["certificado"]}</li>
                                    </abbr>
                                );
                            })}
                        </ul>
                    </td>
                </React.Fragment>
            );
        };

        return (
            values.map((row, index) => {
                const trCSS = row["descartado"] === 1 ? `${styles.viewTableRow} ${styles.SwmTableRowDescartado}` : `${styles.viewTableRow}`;
                return (
                    <tr className={trCSS} key={index}>
                        <td onClick={() => handleRowClick(row)}>{row["equipo"]}</td>
                        <td onClick={() => handleRowClick(row)}>{row["marca"]}</td>
                        <td onClick={() => handleRowClick(row)}>{row["modelo"]}</td>
                        <td onClick={() => handleRowClick(row)}>{row["serie"]}</td>
                        <td onClick={() => handleRowClick(row)}>{row["activo"]}</td>
                        <td onClick={() => handleRowClick(row)}>{row["ubicacion"]}</td>
                        {getInventarioColumns(row)}
                    </tr>
                );
            })
        );
    };

    const getRowsItem = (values) => {
        const getItemColumns = (value) => {
            if (value["url"]) {
                return (
                    <a href={value["url"]} target={"_blank"} rel={"noopener noreferrer"}>
                        <li className={styles.viewTableListItem}>{value["certificado"]}</li>
                    </a>

                );
            }

            return (
                <abbr title={"Pendiente por Aprobación"}>
                    <li className={styles.viewTableListItemDisabled}>{value["certificado"]}</li>
                </abbr>
            );

        };

        return values.map((row, index) => {
            return (
                <tr className={`${styles.viewTableRow} ${styles.nohover}`} key={index}>
                    <td>{row["fecha_emision"]}</td>
                    <td>{row["ot"]}</td>
                    <td>{row["etiqueta"]}</td>
                    <td>{row["responsable"]}</td>
                    <td>
                        <ul className={styles.viewTableList}>
                            {getItemColumns(row)}
                        </ul>
                    </td>
                </tr>
            );
        });

    };

    if (rows < 1) {
        return <div className={styles.emptyMessage}>No se encontraron resultados</div>
    }

    switch (type) {
        case "inventario":
            tableRows = getRowsInventario(rows);
            break;
        case "item":
            tableRows = getRowsItem(rows);
            break;
        default:
            break;
    }

    return (
        <div>
            <div className={styles.viewTablePhantomHeader}/>
            <div className={styles.viewTable}>
                {getTitle(props.title)}
                <table>
                    <thead>
                    <tr className={styles.viewTableHeader}>
                        {header.map((header, index) => {
                            return <th key={index}>{header}</th>
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {tableRows}
                    </tbody>
                </table>
            </div>
        </div>
    );

};

export default SwmTable;