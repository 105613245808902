import React, {useEffect, useState} from "react";
import styles from "../item/item.module.css";
import styles2 from "../inventario/inventario.module.css";
import styles3 from "../table/swmtable.module.css";
import Loading from "../loading/loading";
import Searchbar from "../searchbar/searchbar";
import Swmtable from "../table/swmtable";
import SwmButton from "../button/swmbutton";
import {getInventarioItem} from "../../helpers";
import {useRouteMatch} from "react-router-dom";
import {useHistory} from "react-router-dom";

const Item = () => {
    let history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [certificados, setCertificados] = useState();
    const [searchBarTables, setSearchBarTables] = useState();
    const [isDescartado, setIsDescartado] = useState({
        "theme": null,
        "banner": null
    });
    let {url} = useRouteMatch();
    const tableCertificadosHeader = ["Fecha de Emisión", "Orden de Trabajo", "Etiqueta ID", "Responsable", "Certificado"];

    useEffect(() => {
        const path_values = url.replace('/inventario', '');
        getInventarioItem(path_values).then(response => {
            const datos = response.data["datos"];
            setData({
                "datos": datos,
                "imagenes": response.data["imagenes"]
            });
            setCertificados(response.data["certificados"]);
            setSearchBarTables([{
                "initialValues": response.data["certificados"],
                "searchParameters": ["fecha_emision", "ot", "etiqueta", "responsable", "certificado", "servicio"],
                "setValues": setCertificados
            }]);
            setLoading(false);

            if (datos["descartado"] === 1) {
                setIsDescartado({
                    "theme": `${styles.descartadoColor}`,
                    "banner": <div className={styles.descartadoBanner}>Equipo Descartado</div>
                });
            }

        }).catch(() => {
            history.push("/404");
        });
    }, [url, history]);

    const generateTables = (values) => {
        const servicios = [...new Set(values.map(value => value["servicio"]))].sort();
        return (
            servicios.map((servicio, index) => {
                const data = values.filter(value => value["servicio"] === servicio);
                return <Swmtable key={index} title={servicio} headers={tableCertificadosHeader} rows={data} type={"item"}/>
            })
        );
    };

    const emptyMessage = (values) => {
        if (values < 1) {
            return (
                <div className={styles3.emptyMessage}>No se encontraron resultados</div>
            );
        }
    };

    if (isLoading) {
        return (
            <section className={styles.itemSection}>
                <Loading/>
            </section>
        );
    }

    return (
        <section className={`${styles.itemSection} ${isDescartado.theme}`}>
            <div>
                <div>
                    {isDescartado.banner}
                    <div className={styles.itemTopRow}>
                        <ItemImagen data={data["imagenes"]}/>
                        <ItemDatos data={data["datos"]}/>
                    </div>
                </div>
                <div className={styles2.viewToolbar}>
                    <Searchbar data={searchBarTables} theme={isDescartado.theme}/>
                    <div className={styles.swmButtonContainer}>
                        <SwmButton data={data["datos"]}/>
                    </div>
                </div>
                <div>
                    {generateTables(certificados)}
                </div>
                {emptyMessage(certificados)}
            </div>
        </section>
    );
};

const ItemImagen = (props) => {
    return (
        <div className={styles2.viewModule}>
            <header>
                <span>Imagen del Equipo</span>
            </header>
            <div className={styles.itemImage} style={{backgroundImage: `url(${props.data})`}}/>
        </div>
    );
};

const ItemDatos = ({ data }) => {
    const values = {
        'id': 'ID SIGCSA',
        'equipo': 'Equipo',
        'marca': 'Marca',
        'modelo': 'Modelo',
        'serie': 'Número de Serie',
        'activo': 'Número de Activo',
        'ubicacion': 'Ubicación'
    };

    return (
        <div className={`${styles2.viewModule} ${styles.flex2}`}>
            <header>
                <span>Datos del Equipo</span>
            </header>
            <div>
                <table className={styles.itemDatosTable}>
                    <tbody>
                        {Object.keys(values).map((key, index) => {
                            return (
                                <tr key={index}>
                                    <td className={styles.itemDatosLeft}>{values[key]}</td>
                                    <td className={styles.itemDatosRight}>{data[key]}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Item;

// Referencias
//
// 1. https://stackoverflow.com/questions/24403732/how-to-check-if-array-is-empty-or-does-not-exist
// 2. https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array