import React, {useEffect, useState} from 'react';
import styles2 from "../components/auth/auth.module.css";
import styles from "../components/error404/error404.module.css";
import style3 from "../style/qrcode.module.css";
import styles4 from "../components/table/swmtable.module.css";
import Loading from "../components/loading/loading";
import {getCertificadosFromEtiqueta} from "../helpers";


const Qrcode = (props) => {
    const [certificados, setCertificados] = useState(null);
    const [isLoading, setLoading] = useState(true)
    const [isError, setError] = useState(false);
    const etiquetaId = props.match.params.etiquetaId;

    useEffect(() => {
        const isEtiquetaLegit = () => {
            if (!etiquetaId || typeof etiquetaId !== "string") return false;

            const match = etiquetaId.match(/^(\d{6})-(\w{4,14})$/);
            if (!match) return false;

            const dateStr = match[1];
            const year = Number(`20${dateStr.slice(4, 6)}`);
            const month = Number(dateStr.slice(2, 4));
            const day = Number(dateStr.slice(0, 2));
            const date = new Date(year, month - 1, day);

            if (isNaN(date) || date < new Date(2021, 0, 1) || date > new Date()) return false;

            return true
        };

        const errorExit = () => {
            setError(true);
            setLoading(false);
        };

        if (isEtiquetaLegit()) {
            getCertificadosFromEtiqueta(etiquetaId).then(response => {
                setCertificados([...response]);
                setLoading(false);
            }).catch(() => {
                errorExit();
            })
        }
        else {
            errorExit();
        }

    }, [etiquetaId])

    const successMsg = (values) => {
        return (
            <ul className={styles4.viewTableList}>
                {values.map((value, index) => {
                    let component;
                    if (value["url"]) {
                        component = (
                            <a key={index} href={value["url"]} target={"_blank"}
                               rel={"noopener noreferrer"}>
                                <li className={styles4.viewTableListItem}>{value["certificado"]}</li>
                            </a>

                        );
                    }
                    if (!value["url"] && value["certificado"]) {
                        component = (
                            <abbr title={"Pendiente por Aprobación"}>
                                <li className={styles4.viewTableListItemDisabled}>{value["certificado"]}</li>
                            </abbr>
                        );
                    }
                    return component;
                })}
            </ul>
        );
    };

    const errorMsg = () => {
        return <p>Oops, la etiqueta no existe 😵‍💫</p>
    };

    if (isLoading) return <Loading />

    return (
        <div className={styles2.authBackground}>
            <div className={styles.error404Container}>
                <div className={style3.qrcodeParentContainer}>
                    <img
                        className={styles.error404Logo}
                        src={"https://s3.us-east-2.amazonaws.com/www.sigcsapanama.com/assets/logo.png"}
                        alt="SIGCSA"/>
                    <div className={style3.qrcodeButtonsContainer}>
                        <p>{`Etiqueta ID: ${etiquetaId}`}</p>
                        {isError ? errorMsg() : successMsg(certificados)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Qrcode;