import React, {useEffect, useState} from "react";
import styles from "./isAuth.module.css";
import Navbar from "../navbar/navbar";
import Inventario from "../inventario/Inventario";
import Item from "../item/item";
import Loading from "../loading/loading";
import Header from "../header/header";
import {getInventario, getReportes} from "../../helpers";
import {Route, Switch} from "react-router-dom";
import Reportes from "../reportes/reportes";

const IsAuth = () => {
    const [dataInventario, setDataInventario] = useState();
    const [dataReportes, setDataResportes] = useState();

    useEffect(() => {
        const token = sessionStorage.getItem("token");
        getInventario(token).then(response1 => {
            if (response1) {
                setDataInventario(response1);
                getReportes(token).then(response2 => {
                    if (response2) {
                        setDataResportes(response2);
                    }
                });
            }
        });

    }, []);

    if (dataInventario && dataReportes) {
        return (
            <div className={styles.isAuthContainer}>
                <Navbar/>
                <div className={styles.isAuthBody}>
                    <Header/>
                    <Switch>
                        <Route exact path="/inventario">
                            <Inventario response={dataInventario}/>
                        </Route>
                        <Route path="/inventario/:equipoId/:mainId">
                            <Item/>
                        </Route>
                        <Route exact path="/certificados">
                            <Reportes data={dataReportes}/>
                        </Route>
                    </Switch>
                </div>
            </div>
        );
    }

    return <Loading/>

};

export default IsAuth;

// Referencias
//
// 1. https://codewithnico.com/react-wait-axios-to-render/