import React, {useEffect, useState} from "react";
import styles from "./reportes.module.css";
import styles2 from "../inventario/inventario.module.css";
import SwmTable from "../table/swmtable";
import {getCertificadosArchived} from "../../helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";
import Loading from "../loading/loading";

const Reportes = (props) => {
    const dataInicial = [...props.data];
    const reportesTableHeaders = ["Fecha de Emisión", "Orden de Trabajo", "Etiqueta ID", "Responsable", "Certificado"];

    const [isDownloading, setIsDownloading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [selectors, setSelectors] = useState({
        year: {
            header: "Año",
            filter: [...new Set(dataInicial.map(item => item.year))].sort().reverse()[0]
        },
        ot: {
            header: "Orden de Trabajo",
            filter: [...new Set(dataInicial.map(item => item.ot))].sort().reverse()[0]
        },
        servicio: {
            header: "Servicio",
            filter: ""
        },
        equipo: {
            header: "Equipo",
            filter: ""
        }
    });
    const [data, setData] = useState(() => {
        return dataInicial.filter(item => item.year === selectors.year.filter && item.ot === selectors.ot.filter);
    });

    useEffect(() => {
        let newData = [...props.data];
        const keys = Object.keys(selectors).filter(item => selectors[item].filter !== "");
        keys.forEach(key => {
            newData = newData.filter(item => item[key] === selectors[key].filter);
        });
        setData(newData);
    }, [selectors, props.data]);

    const buttonHandler = () => {
        setIsDownloading(true);
        const requestBody = {"ids": data.map((row) => row["id"])};
        const queryStrings = {
          chsfFormat: document.getElementById('chsfFormat').checked
        }
        getCertificadosArchived(requestBody, queryStrings).then(() => {
            setIsDownloading(false);
            setIsChecked(queryStrings.chsfFormat);
        });
    };

    if (isDownloading) {
        return <Loading/>
    }

    return (
        <section className={styles.reportesContainer}>
            <div>
                <div className={styles.selectorsRow}>
                    {Object.keys(selectors).map((item, index) => {
                        return <SwmSelector key={`SwmSelector${index}`}
                                            id={item}
                                            initialData={dataInicial}
                                            parameters={selectors}
                                            updateParameters={setSelectors}/>
                    })}
                </div>
                <div className={styles.reportesToolbar}>
                    <button style={{margin: "auto"}} className={`${styles2.toolbarButton} ${styles2.DownloadButton}`} onClick={buttonHandler}>
                        <FontAwesomeIcon icon={faFileDownload}/>
                        <span>Descargar Certificados</span>
                    </button>
                    <div style={{margin: "auto", marginTop: 10}}>
                      <input type="checkbox" id="chsfFormat" name="chsfFormat" checked={isChecked} onChange={() => {setIsChecked(!isChecked)}}/>
                      <label for="chsfFormat">Incluir equipo, serie y activo en el nombre del archivo pdf</label>
                    </div>
                </div>
                <div>
                    <SwmTable headers={reportesTableHeaders} rows={data} type={"item"}/>
                </div>
            </div>
        </section>
    );

};

const SwmSelector = (props) => {

    const getOts = (y) => {
        const newData = props.initialData.filter(item => item.year === Number(y));
        return [...new Set(newData.map(item => item.ot))].sort().reverse();
    };

    const getOptions = () => {
        const values = [...new Set(props.initialData.map(item => item[props.id]))].sort();

        switch (props.id) {
            case "year":
                values.reverse();
                return values.map((item, index) => {
                    return <option key={index} value={item}>{item}</option>
                });
            case "ot":
                const ots = getOts(props.parameters.year.filter);
                return ots.map((item, index) => {
                    return <option key={index} value={item}>{item}</option>
                });
            case "servicio":
            case "equipo":
                const tmp = values.map((item, index) => {
                    return <option key={index + 1} value={item}>{item}</option>
                });
                return [<option key={0} value={""}>-- Todos --</option>, ...tmp];
            default:
                break;
        }
    };

    const selectorHandler = (event) => {
        const newValue = event.target.value;
        if (props.id === "year") {
            props.updateParameters({...props.parameters,
                year: {...props.parameters.year, filter: Number(newValue)},
                ot : {...props.parameters.ot, filter: getOts(newValue)[0]}
            });
        }
        else {
            props.updateParameters({...props.parameters, [`${props.id}`]: {...props.parameters[`${props.id}`], filter: newValue}});
        }
    };

    return (
        <div className={styles.selectorContainer}>
            <header className={styles.selectorHeader}>
                {props.parameters[props.id].header}
            </header>
            <div>
                <select className={styles.selectorSelect} onChange={selectorHandler} value={props.parameters[props.id].filter}>
                    {getOptions()}
                </select>
            </div>
        </div>
    );
};

export default Reportes;

// Referencias
//
// 1. https://stackoverflow.com/questions/15125920/how-to-get-distinct-values-from-an-array-of-objects-in-javascript
