import React from "react";
import styles from "./error404.module.css";
import styles2 from "../auth/auth.module.css";

const Error404 = () => {
    return (
        <div className={styles2.authBackground}>
            <div className={styles.error404Container}>
                <div>
                    <img
                        className={styles.error404Logo}
                        src={"https://s3.us-east-2.amazonaws.com/www.sigcsapanama.com/assets/logo.png"}
                        alt="SIGCSA"/>
                    <p><b>404</b> Esto es un Error</p>
                    <p>La página que buscas no existe</p>
                </div>
                <div className={styles.error404Emoji}>
                    😵‍💫
                </div>
            </div>
        </div>
    );
};

export default Error404;